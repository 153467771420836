import gsap from 'gsap';
import React, { useRef } from 'react';

import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";

function Hero() {


    let animState = false

    function anim() {
        if (!animState) {
            gsap.to('#full-screen-video', {
                y: 0,
            })
            animState = true
        } else {
            gsap.to('#full-screen-video', {
                y: '100%',
            })
            animState = false
        }
    }

    function removeThumb() {
        document.querySelector('#hero > #video > img')?.remove()
        document.querySelector('#hero > #video > video')?.classList.remove('hidden')
    }

    return (
        <section id="hero" className='h-[90vh] w-full flex flex-col md:flex-row'>
            <div id='video' className='w-full md:w-2/3 h-1/2 md:h-full group relative cursor-pointer' onClick={anim}>
                <div className='absolute m-auto group-hover:opacity-100 opacity-0 z-50 w-full h-full grid place-items-center'>
                    <AiOutlineFullscreen size={150} className='text-white' />
                </div>
                <div id='bg' className='w-full h-full absolute top-0 left-0 bg-gradient-to-b md:bg-gradient-to-r from-transparent to-green via-transparent z-20'></div>
                <img src='./thumb.jpg' alt='' className='w-full h-full object-cover object-center-30' />
                <video playsInline autoPlay loop muted className='w-full h-full object-cover object-center-30 group-hover:brightness-50 duration-200 cursor-pointer hidden' onCanPlayThrough={removeThumb}>
                    <source src='./video banner.mp4'></source>
                </video>
            </div>
            <div className='w-full md:w-1/3 h-full relative'>
                <div className='w-full h-full z-50 relative flex flex-col items-center justify-center drop-shadow-lg'>
                    <img src='./logo-azuis.svg' alt='Azuis' className='w-[150px] xl:w-[250px] 2xl:w-[300px]' />
                    <div className='text-white flex flex-col items-center mt-10 px-10'>
                        <h1 className='text-2xl md:text-4xl font-bold'>100% VENDIDO</h1>
                        <p className='text-lg text-center text-balance'>Obrigado por fazer parte deste sucesso</p>
                    </div>
                </div>
                <div className='w-full h-full absolute top-0 left-0 bg-gradient-to-t md:bg-gradient-to-l from-[#1f7376e8] to-green via-[#1f7376e8] z-20'></div>
                <img className='absolute w-full h-full object-cover top-0 left-0 z-10' src='./fachada01.jpg' alt='fachada' />
            </div>
            <div id='full-screen-video' className='fixed w-full h-screen top-0 left-0 flex items-center justify-center z-50' style={{ transform: 'translateY(100%)' }}>
                <video controls className=' h-[70vh] md:h-[90vh] object-contain z-20'>
                    <source src='./video banner.mp4'></source>
                </video>
                <div className='absolute top-0 left-0 h-full w-full bg-black opacity-40 z-10 cursor-pointer' onClick={anim}></div>
            </div>
        </section>
    );
}

export default Hero;