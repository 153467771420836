import React from 'react';
import { HiCursorClick } from "react-icons/hi";

function Menu() {

    function scrollIntoView() {
        document.getElementById('acervo')?.scrollIntoView({
            behavior: 'smooth'
        })
    }

    return (
        <nav className='relative bg-green2 text-white flex justify-between items-center py-5 px-5 md:px-10 cursor-pointer hover:bg-green duration-150 max-w-screen z-50' onClick={scrollIntoView}>
            <img src='./img/mozak.png' alt='Mozak' className='w-[70px] md:w-[100px]' />
            <div className='absolute inset-0 flex items-center justify-center gap-5'>
                <h1 className='hidden md:block text-sm'>Procurando um novo residencial Mozak?</h1>
                <HiCursorClick size={18} />
            </div>
            <span className='text-sm md:text-xl text-white font-medium  tracking-wider'>BS"D</span>
        </nav>
    );
}

export default Menu;