import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation, Autoplay } from 'swiper/modules';

import img1 from '../assets/AMozak/01.jpg'
import img2 from '../assets/AMozak/02.jpg'
import img3 from '../assets/AMozak/03.jpg'
import img4 from '../assets/AMozak/04.jpg'
import img5 from '../assets/AMozak/05.jpg'
import img6 from '../assets/AMozak/06.jpg'

import Mozak30Anos from '../assets/AMozak/30AnosMozak.png'


function Carosel() {

    return (
        <Swiper
            navigation={true}
            modules={[Navigation, Autoplay]}
            autoplay={{ delay: 3000 }}
            loop
            className="w-full aspect-square md:rounded-br-[40px] text-white"
        >
            <SwiperSlide key={1}>
                <img className="w-full h-full object-cover" src={img1} alt='Imagem 1' />
            </SwiperSlide>
            <SwiperSlide key={2}>
                <img className="w-full h-full object-cover" src={img2} alt='Imagem 2' />
            </SwiperSlide>
            <SwiperSlide key={3}>
                <img className="w-full h-full object-cover" src={img3} alt='Imagem 3' />
            </SwiperSlide>
            <SwiperSlide key={4}>
                <img className="w-full h-full object-cover" src={img4} alt='Imagem 4' />
            </SwiperSlide>
            <SwiperSlide key={5}>
                <img className="w-full h-full object-cover" src={img5} alt='Imagem 5' />
            </SwiperSlide>
            <SwiperSlide key={6}>
                <img className="w-full h-full object-cover" src={img6} alt='Imagem 6' />
            </SwiperSlide>
        </Swiper>
    )
}

function Content() {
    return (
        <div className='flex flex-col justify-center sm:items-start sm:px-16 w-full h-auto px-4 sm:text-start text-center items-center'>
            <img className='sm:w-2/3 w-4/5 mt-6' src={Mozak30Anos} alt='Mozak 30 Anos' />
            <h1 className='mt-8 text-[1.2rem] font-bold text-[#282828] underline'>Sobre nós</h1>
            <p className='text-[1.2rem] font-light mt-3 w-auto'>
                Há três décadas assumimos o compromisso de
                construir imóveis exclusivos e sofisticados para
                melhorar o conceito de viver e hoje seguimos fiéis
                ao nosso propósito.
            </p>
            <p className='text-[1.2rem] font-light mt-3 sm:w-auto'>
                Com atendimento personalizado, unimos
                exclusividade, design autoral e arquitetura
                assinada, sempre pensando em quem busca
                melhor viver e investir na zona sul do Rio.
            </p>
            <p className='text-[1.2rem] font-light mt-3 sm:w-auto'>
                Mozak – a arte de viver o Rio em toda a sua essência
            </p>
            <a href='https://mozak.rio/negocio/' target='_blank' rel='noreferrer' className='mt-6 rounded-full bg-[rgba(50,50,50)] px-4 py-2 text-white no-underline transition-all duration-200 ease-in-out hover:bg-[rgba(0,0,0)]'>
                Saiba mais sobre a Mozak
            </a>
        </div>
    )
}

function AMozak() {
    return (
        <section id='timeline-mozak' className='flex sm:flex-row sm:gap-0 gap-8 sm:px-40 py-12 sm:py-32 mx-0 relative px-0 flex-col bg-[#e8e5db]'>
            <Carosel />
            <Content />
        </section>
    );
}

export default AMozak;
